body {
  margin: 0;
  padding: 0;
}

.title {
  word-break: normal;
}

.section,
.hero-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.column {
  flex-basis: unset;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-footer {
  margin-top: auto;
}

.redux-toastr .toastr .close-toastr {
  opacity: 1;
  color: #fff;
}

.redux-toastr .toastr .rrt-left-container {
  width: 75px;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
  width: 26px !important;
  height: 26px !important;
}

.redux-toastr .toastr .rrt-middle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 75px;
  min-height: 70px;
}

.error {
  color: red;
}

.error::before {
  display: inline;
  content: '⚠ ';
}
aside.aside{
  background-color: #006837 !important;
  .aside-tools{
    background-color: #7abd7d !important;
  }
  .menu-list{
    a{
      color: #fff;
      &.is-active,
      &:hover{
        background-color: #024f2b;
      }
    }
  }
}
.smooth-dnd-container{
  min-height: 88vh;
}
#root{
  .table{
    &.is-hoverable{
      tbody{
        tr{
          &:not(.is-selected){
            &:hover{
              background-color: #f5f5f5;
            }
          }
        }
      }
    }
  }
}
.button{
  &.blue-button{
    background-color: #133b7b;
    color: #fff;
    &:hover{
      color: #fff;
    }
  }
}
.input[readonly],
.textarea[readonly]{
  pointer-events: none;
  cursor: not-allowed;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  box-shadow: none;
  color: #7a7a7a;
}

.b-checkbox.checkbox input[type=checkbox]:checked + .check.is-blue{
  border-color: #133b7b;
  background-color: #133b7b;
}

.b-checkbox.checkbox:hover input[type=checkbox]:not(:disabled) + .check.is-blue{
  border-color: #133b7b;
}
.icon{
  &.big-icon{
    height: 2rem;
    width: 2rem;
    font-size: 2.5rem;
  }
  &.green-icon{
    color: #00d1b2;
  }
  &.red-icon{
    color: #f14668;
  }
}

@media screen and (min-width: 769px){
  .field-body {
    align-items: center;
  }
}