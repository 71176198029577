.tooltip-component{
  display: inline-flex;
  position: relative;
  .icon{
    cursor: pointer;
  }
  .tooltip-content{
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    background: #000;
    z-index: 10;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    max-width: 300px;
    width: max-content;
    transform: translateX(-50%);
  }
  .icon:hover + .tooltip-content{
    display: block;
  }
}