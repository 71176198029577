@media screen and (max-width: 768px) {
  div.preview {
    display: none;
  }
}

.b-checkbox.checkbox input[type=checkbox][readonly] + .check {
  background-color: #E5E5E5;
}

.b-checkbox.checkbox input[type=checkbox][readonly]:checked + .check.is-primary {
  background-color: #E5E5E5;
  border-color: #E5E5E5;
}