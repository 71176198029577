.devices-loading{
	position: relative;
	&:before{
		position: absolute;
		width: 100%;
		height: 100%;
		content: '';
		z-index: 100;
		opacity: 0.1;
		background: #133b7b;
	}
}