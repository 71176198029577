.el{
	border: 10px solid #E4E6E6;
	border-radius: 0.25rem;
	background: #E4E6E6;
	.elHeader{
		position: relative;
		.elNameWrap{
				flex: 1;
				min-width: 0;
				overflow: hidden;
				text-overflow: ellipsis;
			.elName{
				white-space: nowrap;
			}
		}
		.elActionIcon{
			border-radius: 50%;
			&:hover{
				background: #B5B5B5;
			}
		}
		.actionBlock{
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			border-radius: 0.25rem;
			box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
			background: #fff;
			padding: 10px 0;
			white-space: nowrap;
			.actionBlockItem{
				padding: 5px 15px;
				cursor: pointer;
				&:hover{
					background: #B5B5B5;
				}
			}
		}
	}
	.elContent{
		border-radius: 0.25rem;
		background: #fff;
		padding-top: 75%;
		position: relative;
		.elContentInner{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 5px;
			img{
				max-height: 100%;
			}
		}
	}
}