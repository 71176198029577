.dragDropContainer{
  height: 20vh;
  width: 100%;
  border: 2px dashed #7A7A7A;
  border-radius: 6px;
  padding: 10px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active{
    background: #fff;
  }
  .dragElement{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}