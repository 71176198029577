.wrapper{
	width: 100%;
	height: 50vh;
	position: relative;
	&.fullHeigth{
		height: 100%;
	}
	.loader{
		display: inline-block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}