.loaderWrapper{
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 100;
	top: 0;
	left: 0;
}
.modalContentInner{
	display: none;
	height: 100%;
}
.folderSize{
	white-space: nowrap;
}
.metaTable{
	tr{
		th{
			border: none;
			padding-left: 0;
		}
		td{
			border: none;
		}
	}
}
.el{
	border: 10px solid #E4E6E6;
	border-radius: 0.25rem;
	background: #E4E6E6;
	.elHeader{
		.elNameWrap{
				flex: 1;
				min-width: 0;
				overflow: hidden;
				text-overflow: ellipsis;
			.elName{
				white-space: nowrap;
			}
		}
	}
	.elContent{
		border-radius: 0.25rem;
		background: #fff;
		padding-top: 75%;
		position: relative;
		.elContentInner{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 10px;
			img{
				max-height: 100%;
			}
		}
	}
}
.filesTable{
	tr{
		td{
			vertical-align: middle;
			&:first-child{
				width: 1%;
				img{
					max-width: none;
				}
			}
			.folder-name{
				cursor: pointer;
			}
			&.full-width{
				width: auto;
			}
		}
	}
}