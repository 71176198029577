.content-modal-container{
  .modal-content{
    overflow: hidden;
    img{
      max-height: 100%;
      width: auto;
      margin: 0 auto;
      display: block;
      height: auto;
    }
  }
}